import React, { useState, useRef, useImperativeHandle, useEffect, ReactElement } from "react";
import * as Styled from "./style";

import { useResize } from "../../utils/hooks";
import { useObjectFit, ObjectFitType } from "../../utils/hooks/use-object-fit";

import { default as parseSrcSet, SrcSetItem } from "./helper/parse-srcset";

export type VideoSource = React.ReactElement<React.SourceHTMLAttributes<HTMLSourceElement>>;

interface Props extends Omit<React.VideoHTMLAttributes<HTMLVideoElement>, "preload" | "src" | "poster"> {
  poster?: ReactElement;
  children: VideoSource | VideoSource[];
  lazy?: boolean;
  objectFit?: ObjectFitType;
  objectPosition?: string;
  style?: React.CSSProperties;
  videoRef?: React.RefObject<HTMLVideoElement | null>;
  testId?: string;
}

interface SourceItem {
  src?: string;
  srcSet: SrcSetItem[];
  type?: string;
}

const VideoComponent = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      poster,
      lazy = false,
      children,
      objectFit,
      objectPosition,
      style,
      videoRef: externalVideoRef,
      className,
      testId,
      ...videoProps
    },
    ref,
  ) => {
    // use state to remember once video has been set to load
    const [load, setLoad] = useState<boolean>(!lazy);
    if (!lazy && !load) setLoad(true);

    // get all source data
    const sources: SourceItem[] =
      React.Children.map(children as VideoSource[], (child) => {
        return {
          src: child.props.src,
          srcSet: parseSrcSet(child.props.srcSet),
          type: child.props.type,
        };
      }) || [];

    // ensure source units are supported
    if (sources.some((source) => source.srcSet.some((srcset) => srcset.unit !== "w"))) {
      throw new Error('srcset only supports "w" units');
    }

    const posterRef = useRef<HTMLDivElement>(null);
    const [posterHidden, setPosterHidden] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement>(null);
    useImperativeHandle(externalVideoRef, () => videoRef.current);

    let defaultSourceSrc: string | undefined;
    let responsiveSources: SourceItem[] | undefined;

    const updateSrc = () => {
      const video = videoRef.current;
      if (!video) return;
      if (defaultSourceSrc === null) return;
      if (!responsiveSources) return;

      // if video already loaded, then forget it
      if (load) return;

      // if video ready to play, don't switch it
      if (video.readyState > 0) return;

      // get width of current videoQ
      const videoSize = video.getBoundingClientRect().width;

      let matchingSrcSet: SrcSetItem | undefined;
      let biggestSrcSet: SrcSetItem | undefined;

      // find the best matching srcset item
      responsiveSources.map((source) => {
        source.srcSet.map((srcset) => {
          // keep track of biggest src set in case others don't match
          if (!biggestSrcSet || biggestSrcSet.size < srcset.size) {
            biggestSrcSet = srcset;
          }

          // check if size is at least as big as required size
          if (srcset.size > videoSize) {
            // existing matching srcset could already be a better match if smaller
            if (!matchingSrcSet || matchingSrcSet.size > srcset.size) {
              matchingSrcSet = srcset;
            }
          }
        });
      });

      const src = matchingSrcSet ? matchingSrcSet.url : biggestSrcSet ? biggestSrcSet.url : defaultSourceSrc;

      if (!src) return;

      const normalizedSrc = ((url) => {
        const a = document.createElement("a");
        a.href = url;
        return a.href;
      })(src);

      if (video.currentSrc !== normalizedSrc) {
        // console.log(video.currentSrc, normalizedSrc)
        video.src = normalizedSrc;
      }
    };

    useEffect(() => {
      responsiveSources = undefined;
      defaultSourceSrc = undefined;

      const video = videoRef.current;
      if (!video) return;
      if (sources.length < 1) return;

      // default to first playable source
      const playableSources = sources.filter((source) => {
        return source.src && source.type && video.canPlayType(source.type);
      });
      if (playableSources.length) defaultSourceSrc = playableSources[0].src;

      if (!defaultSourceSrc) {
        defaultSourceSrc = sources.filter((source) => {
          return source.src;
        })[0]!.src;
      }

      responsiveSources = sources
        // only watch playable sources
        .filter((source) => {
          // no source files set
          if (!source.src && source.srcSet.length === 0) return false;

          // unplayable
          return source.type && video.canPlayType(source.type) !== "";
        })
        // only use sources with more than one srcset item
        .filter((source) => {
          return source.srcSet.length > 1;
        });

      updateSrc();
    }, [sources, videoRef.current]);
    useResize(() => updateSrc(), []);

    useEffect(() => {
      const videoEl = videoRef.current;
      const posterEl = posterRef.current;

      if (!videoEl) return;
      if (!posterEl) return;

      const playHandler = () => {
        setPosterHidden(true);
      };

      videoEl.addEventListener("playing", playHandler);

      return () => videoEl.removeEventListener("playing", playHandler);
    }, [videoRef.current, posterRef.current]);

    const [videoStyle] = useObjectFit(videoRef, { objectFit, objectPosition });

    return (
      <Styled.Wrapper className={className} ref={ref} data-testid={`${testId}:videocontainer`}>
        {poster && (
          <Styled.Poster ref={posterRef} hidden={posterHidden}>
            {poster}
          </Styled.Poster>
        )}
        <Styled.Video
          ref={videoRef}
          preload={load ? "metadata" : "none"}
          style={{ ...style, ...videoStyle }}
          data-testid={`${testId}:video`}
          {...videoProps}
        >
          {children}
        </Styled.Video>
      </Styled.Wrapper>
    );
  },
);

export default VideoComponent;
export { srcset, sizes } from "../image/helper";
