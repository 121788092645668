import styled from "styled-components";

export const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  display: flex;

  &::before,
  &::after {
    content: "";
    display: block;
    flex: 1 1 100%;
    height: 1px;
    background-color: #c1c1c1;
  }
`;

export const FlourishContainer = styled.div`
  flex: 0 0 auto;
  width: 23px;
`;

export const Flourish = styled.svg`
  position: relative;

  display: block;
  width: 100px;
  height: 13px;

  //left: -33px;
  left: 50%;
  transform: translateX(-50%);

  .st0 {
    fill: #c1c1c1;
  }
  .st1 {
    fill: none;
    stroke: #c1c1c1;
    stroke-miterlimit: 10;
  }
`;
