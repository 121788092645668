import React, { useState } from "react";
import Category from "../format/category";
import {
  ResponsiveWrapper,
  WrapperInner,
  Image,
  ImageInner,
  ImageHexOutline,
  ImageWrapper,
  Info,
  InfoInner,
  Title,
  Blurb,
  Meta,
  Author,
  TimeAgo,
} from "./style";
import { LinkProps } from "../link";

export interface ArticleExcerptProps {
  id: string;
  link: LinkProps;
  image: React.ReactElement;
  title?: string;
  category?: string;
  text?: string;
  authors?: string[];
  date?: Date;
  animationDelay?: number;
  isVisible?: boolean;
  hideHexOutline?: boolean;
  testId?: string;
}

const IMAGE_DELAY = 600;
const INFO_DELAY = 800;

const SectionNewsLatest: React.FC<ArticleExcerptProps> = ({
  link,
  image,
  title,
  category,
  text,
  authors,
  date,
  animationDelay = 0,
  isVisible = true,
  hideHexOutline = false,
  testId,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const author = authors && authors.length > 0 ? authors.join(", ") : undefined;
  return (
    <ResponsiveWrapper
      {...link}
      className={isVisible ? "isVisible" : ""}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <WrapperInner>
        <Image>
          <ImageInner hideHexOutline={hideHexOutline} data-testid={`${testId}:image`}>
            {!hideHexOutline && (
              <ImageHexOutline
                isActive={isVisible}
                transitionDelay={animationDelay}
                clipRightTop={20}
                offsetVertical={1}
                offsetHorizontal={-1}
                interactive
                hovering={isHovering}
                hoverDuration={300}
              />
            )}
            <ImageWrapper animationDelay={animationDelay + IMAGE_DELAY}>{image}</ImageWrapper>
          </ImageInner>
        </Image>
        <Info animationDelay={animationDelay + INFO_DELAY}>
          <InfoInner>
            {category && <Category data-testid={`${testId}:category`}>{category}</Category>}
            <Title data-testid={`${testId}:title`}>{title}</Title>
            {text && <Blurb data-testid={`${testId}:description`}>{text}</Blurb>}
            <Meta>
              {author && <Author data-testid={`${testId}:author`}>{author}</Author>}
              {date && <TimeAgo dateTime={date} testId={`${testId}:date`}/>}
            </Meta>
          </InfoInner>
        </Info>
      </WrapperInner>
    </ResponsiveWrapper>
  );
};

export default SectionNewsLatest;
export { rowLayout, columnLayout } from "./style";
