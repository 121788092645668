import styled, { css } from "styled-components";
import { columnLayout as articleExcerptColumnLayout } from "../../../../components/article-excerpt";
import { MOBILE_MARGIN } from "../../../../layouts/default/theme";

export const Wrapper = styled.div``;

export const WrapperInner = styled.div``;

export const List = styled.ol`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

export const Item = styled.li`
  box-sizing: border-box;
  flex: 0 0 auto;
  min-width: 0;
`;

const BREAKPOINT = 800;
const ITEM_MARGIN = "5%";
const ITEM_MARGIN_MOBILE = MOBILE_MARGIN + "px";
export const ResponsiveWrapper = styled(Wrapper)`
  ${articleExcerptColumnLayout()}

  @media (min-width: ${BREAKPOINT + 1 + "px"}) {
    ${List} {
      margin: -${ITEM_MARGIN} -${ITEM_MARGIN} 0 0;
    }
    ${Item} {
      padding: ${ITEM_MARGIN} ${ITEM_MARGIN} 0 0;
    }
  }
  @media (max-width: ${BREAKPOINT + "px"}) {
    ${List} {
      margin: -${ITEM_MARGIN_MOBILE} -${ITEM_MARGIN_MOBILE} 0 0;
    }
    ${Item} {
      padding: ${ITEM_MARGIN_MOBILE} ${ITEM_MARGIN_MOBILE} 0 0;
    }
  }
`;

interface CssLayout {
  columns: number;
}
export const cssLayoutFn = ({ columns }: CssLayout) => css`
  ${Item} {
    width: ${100 / columns + "%"};
  }
`;
