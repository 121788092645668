import styled, { css, keyframes } from "styled-components";
import { easing } from "../../utils/style";

export const Wrapper = styled.div`
  position: relative;
  z-index: 0;
`;

const posterFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const posterFadeOut = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }
  99% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`;

export const Poster = styled.div<{ hidden: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  animation: ${posterFadeIn} 0.4s ${easing.easeInOutCubic} 0.1s 1 both;

  * {
    width: 100%;
    height: 100%;
  }

  ${({ hidden }) =>
    hidden &&
    css`
      display: block;
      animation: ${posterFadeOut} 0.4s ${easing.easeInOutCubic} 0s 1 forwards;
    `}
`;

export const Video = styled.video`
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
`;
