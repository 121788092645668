import styled, { css } from "styled-components";
import { fluidRange } from "polished";

import {
  COLOR_TEXT_WHITE,
  MEDIA_MOBILE_ALL,
  MEDIA_NOT_MOBILE,
  BREAKPOINT_MOBILE_LARGE,
  BREAKPOINT_TABLET_ALL,
  COLUMN_WIDTH,
  MOBILE_MARGIN,
} from "../../../layouts/default/theme";
import HexOutline from "../../../components/hex-outline";
import { cssLocalizedFontFamilySerif, cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../components/css";

export const SocialContentWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const StyledHexOutline = styled(HexOutline)`
  display: inline-block;
  width: inherit;
  max-width: 1680px;
  height: inherit;
  margin: 0 auto;
  z-index: 0;
`;

export const SocialContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

export const Text = styled.div`
  display: inline-block;
  ${cssLocalizedUppercase}
  color: ${COLOR_TEXT_WHITE};
  ${cssLocalizedFontFamilySerif}
  font-weight: 600;
  letter-spacing: 0.04em;
`;
export const Icons = styled.div`
  display: inline-block;
`;

export const SocialIcon = styled.svg`
  display: inline-block;
  width: 24px;
  height: 24px;

  .st0 {
    fill: ${COLOR_TEXT_WHITE};
  }
`;

export const Link = styled.a`
  display: inline-block;
  height: 24px;
  width: 24px;
`;

const rowLayout = css`
  margin-left: ${COLUMN_WIDTH + "px"};
  margin-right: ${COLUMN_WIDTH + "px"};

  ${SocialContent} {
    flex-direction: row;
    justify-content: center;
  }

  ${Text} {
    text-align: right;
    padding: 0 30px;
    margin-right: 22px;
    font-size: 18px;
    border-right: 1px solid ${COLOR_TEXT_WHITE};
  }

  ${Icons} {
    margin: 16px 0;
    padding-right: 20px;
  }

  ${Link} {
    padding: 4px 8px;
  }
`;

const columnLayout = css`
  margin-left: ${MOBILE_MARGIN + "px"};
  margin-right: ${MOBILE_MARGIN + "px"};

  ${SocialContent} {
    flex-direction: column;
    justify-content: space-between;
  }

  ${Text} {
    margin: 0 auto;
    text-align: center;
    padding: 10px;
    font-size: 10px;
    ${cssLocalizedLineHeight(20 / 10)}
    border-right: none;
  }

  ${Icons} {
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
  }

  ${Link} {
    margin: 0 8px 12px 8px;
  }
`;

export const ResponsiveWrapper = styled.div`
  ${fluidRange(
    ["margin-top", "margin-bottom"].map((prop) => ({
      prop,
      fromSize: "50px",
      toSize: "70px",
    })),
    BREAKPOINT_MOBILE_LARGE + 1 + "px",
    BREAKPOINT_TABLET_ALL + "px",
  )}

  @media ${MEDIA_NOT_MOBILE} {
    ${rowLayout}
  }

  @media ${MEDIA_MOBILE_ALL} {
    ${columnLayout}
  }
`;
