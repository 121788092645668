import React from "react";
import { VideoData, VideoDataSource } from "./typings";
import { ImageAssetUrl } from "../../assets/typings";
import Image from "../../../../lib/components/image";
import Video, { VideoSource } from "../../../../lib/components/video";
import videosToSource from "../../../../lib/utils/videos-to-sources";

export default function videoDataToVideoComponent(videoData: VideoData) {
  return (
    <Video poster={videoDataPosterUrlToVideoPoster(videoData.posterUrl)}>
      {videoDataSourcesToVideoSources(videoData.sources)}
    </Video>
  );
}

export const videoDataPosterUrlToVideoPoster = (
  posterUrl: ImageAssetUrl | null | undefined,
): React.ReactElement | undefined => {
  if (!posterUrl) return undefined;
  return <Image src={posterUrl} objectFit={"cover"} />;
};

export const videoDataSourcesToVideoSources = (sources: VideoDataSource[]): VideoSource[] => {
  return videosToSource(sources);
};

export { VideoData } from "./typings";
