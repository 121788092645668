import React, { useState, useEffect } from "react";
import { ease } from "../../utils/math";
import { COLOR_PRIMARY_BLUE, COLOR_PRIMARY_GOLD } from "../../layouts/default/theme";

import HexOutline from "../hex-outline";

import { Anchor } from "./style";
import LinkComponent, { LinkProps } from "../link";

interface Props {
  isActive?: boolean;
  variant?: "primary" | "secondary";
  toggled?: boolean;
  link?: LinkProps;
  className?: string;
  onClick?: () => void;
  transitionDelay?: number;
  hoverDuration?: number;
  hoverEase?: ease.EasingFn;
  fillColor?: string;
  strokeColor?: string;
  testId?: string;
}

const Component: React.FC<Props> = ({
  isActive = true,
  variant = "primary",
  toggled = true,
  link,
  className,
  onClick,
  transitionDelay = 0,
  hoverDuration = 150,
  hoverEase = ease.inOutQuart,
  fillColor,
  strokeColor,
  children,
  testId,
}) => {
  const [hasEntered, setHasEntered] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (isActive && !hasEntered) setHasEntered(true);
  }, [isActive]);

  const commonProps = {
    className: `variant-${variant} ${className} ${hasEntered && "is-visible"}`,
    onClick,
    onMouseEnter: () => setIsHovering(true),
    onMouseLeave: () => setIsHovering(false),
    "data-transition-delay": transitionDelay,
  };

  const inner = (
    <React.Fragment>
      <HexOutline
        isActive={isActive}
        clipLeftTop={10}
        clipRightBot={10}
        fillColor={
          fillColor
            ? fillColor
            : toggled
            ? variant === "secondary"
              ? COLOR_PRIMARY_GOLD
              : COLOR_PRIMARY_BLUE
            : undefined
        }
        strokeColor={
          strokeColor
            ? strokeColor
            : toggled
            ? "transparent"
            : variant === "secondary"
            ? COLOR_PRIMARY_GOLD
            : COLOR_PRIMARY_BLUE
        }
        interactive
        transitionDuration={300}
        transitionDelay={transitionDelay}
        hovering={isHovering}
        hoverDuration={hoverDuration}
        hoverEase={hoverEase}
      />
      <span>{children}</span>
    </React.Fragment>
  );

  if (link) {
    return (
      <Anchor as={LinkComponent} {...link} {...commonProps} testId={testId}>
        {inner}
      </Anchor>
    );
  } else {
    return (
      <Anchor as="button" {...commonProps} data-testid={testId}>
        {inner}
      </Anchor>
    );
  }
};

export default Component;
