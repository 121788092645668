export interface SrcSetItem {
  url: string;
  size: number;
  unit: string;
}

const parseSrcSetRegex = /([^\s,]\S*)\s+(\d+)(x|w)/g;

export default function (data: string | undefined): SrcSetItem[] {
  if (!data) return [];

  const srcs: any[] = [];

  while (true) {
    const matches = parseSrcSetRegex.exec(data);
    if (matches === null) break;

    srcs.push({
      url: matches[1],
      size: parseFloat(matches[2]),
      unit: matches[3],
    });
  }

  return srcs;
}
