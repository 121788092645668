import React from "react";
import { VideoSource } from "../components/video";

export interface Video {
  width?: number;
  url: string;
  type: string;
}

export default function videosToSource(videos: Video[]): VideoSource[] {
  return videos
    .reduce<Array<{ type: string; videos: Video[] }>>((videosByType, video) => {
      const type = video.type;
      let typeGroup = null;

      // check if type group already exists
      for (const group of videosByType) {
        if (group.type === video.type) {
          typeGroup = group;
          break;
        }
      }

      // create new group for type if not found
      if (!typeGroup) {
        typeGroup = {
          type,
          videos: [],
        };
        videosByType.push(typeGroup);
      }

      // add video to group
      typeGroup.videos.push(video);
      return videosByType;
    }, [])
    .map(({ type, videos: typeVideos }) => {
      // get all videos that have a width specified
      const srcSetVideos = typeVideos.filter(({ width }) => !!width);

      // combine videos with widths into src-set
      const srcSet =
        srcSetVideos.length >= 1 ? srcSetVideos.map(({ width, url }) => `${width}w ${url}`).join(", ") : undefined;

      return <source key={type} src={typeVideos[0].url} srcSet={srcSet} type={type} />;
    });
}
