import React from "react";

import { ReactComponent as FlourishSvg } from "./assets/flourish.svg";

import { Wrapper, FlourishContainer, Flourish } from "./style";

const StyledFlourish = Flourish.withComponent(FlourishSvg);

export interface Props {
  className?: string;
}

const Section: React.FC<Props> = ({}) => {
  return (
    <Wrapper>
      <FlourishContainer>
        <StyledFlourish />
      </FlourishContainer>
    </Wrapper>
  );
};

export default Section;
