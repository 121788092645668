import styled, { css } from "styled-components";
import { rem, animation, hoverEffect } from "../../utils/style";
import { COLUMN_WIDTH, MEDIA_TABLET_AND_SMALLER } from "../../layouts/default/theme";
import HexOutline from "../hex-outline";
import TimeAgoComponent from "../time-ago";
import LinkComponent from "../link";
import { cssLocalizedFontFamilySansSerif, cssLocalizedLineHeight } from "../css";

const IMAGE_RATIO = 9 / 16;

export const Wrapper = styled(LinkComponent)`
  color: inherit;
  &:hover {
    text-decoration: none;
  }
`;

export const WrapperInner = styled.article`
  display: flex;
`;

export const Image = styled.div`
  width: 100%;
  max-width: ${rem(COLUMN_WIDTH * 7)};
`;

export const ImageInner = styled.div<{ hideHexOutline: boolean }>`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: calc(${IMAGE_RATIO} * 100%);
  ${({ hideHexOutline }) => {
    return (
      !hideHexOutline &&
      css`
        margin: 10px 0;
      `
    );
  }}
`;

export const ImageHexOutline = styled(HexOutline)`
  z-index: 1;
`;

export const ImageWrapper = styled.div<{ animationDelay: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ animationDelay }) =>
    animation.triggeredFadeIn(`${Wrapper}.isVisible &`, {
      delay: animationDelay,
    })}

  ${hoverEffect.scaleDown(Wrapper)}

  > * {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const Info = styled.div<{ animationDelay: number }>`
  box-sizing: border-box;

  ${({ animationDelay }) =>
    animation.triggeredTranslateFadeIn(`${Wrapper}.isVisible &`, {
      delay: animationDelay,
    })}
`;

export const InfoInner = styled.div`
  max-width: 350px;
`;

export const Title = styled.h2`
  ${cssLocalizedFontFamilySansSerif};
  margin-top: ${rem(5)};
  font-size: ${rem(24)};
  ${cssLocalizedLineHeight(1.2)}
`;

export const Blurb = styled.p`
  ${cssLocalizedFontFamilySansSerif};
  margin: 10px 0;
  color: #1a1d21;
  font-size: ${rem(12)};
  ${cssLocalizedLineHeight(1.5)}
`;

export const Meta = styled.div`
  margin-top: 14px;
  font-size: 12px;
  font-weight: 600;
`;

export const Author = styled.span`
  &::after {
    content: "–";
    display: inline-block;
    margin: 0 0.6em;
  }
`;

export const TimeAgo = styled(TimeAgoComponent)`
  white-space: nowrap;
`;

export const ResponsiveWrapper = styled(Wrapper)`
  ${Title}, ${Meta} {
    letter-spacing: 0.75px;
  }
`;

export const rowLayout = () => css`
  ${ResponsiveWrapper} {
    ${WrapperInner} {
      flex-direction: row;
    }

    ${Image} {
      flex: 1 1.5 auto;
      padding-right: 4%;
      min-width: 0;
    }

    ${Info} {
      align-self: center;
      flex: 2 1 auto;
      width: 75%;
    }
  }
`;

export const columnLayout = () => css`
  ${ResponsiveWrapper} {
    ${WrapperInner} {
      flex-direction: column;
    }

    ${Image} {
      margin: 0 auto;
    }

    ${Info} {
      width: 100%;
      margin-top: 10px;
      padding: 0 10px;
    }

    ${InfoInner} {
      margin: 0 auto;
    }

    ${Title} {
      font-size: ${rem(20)};

      @media ${MEDIA_TABLET_AND_SMALLER} {
        font-size: ${rem(14)};
      }
    }
  }
`;
