import styled, { keyframes } from "styled-components";
import { rem, easing } from "../../utils/style";
import { COLOR_TEXT_WHITE } from "../../layouts/default/theme";
import { cssLocalizedUppercase } from "../css";

const simpleFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Anchor = styled.a<{ "data-transition-delay": number }>`
  box-sizing: border-box;

  position: relative;

  display: inline-block;
  min-width: ${rem(170)};
  padding: ${rem(10)} ${rem(20)};
  margin: 0 auto;

  color: ${COLOR_TEXT_WHITE};

  &.variant-primary {
    color: ${({ theme }) =>
      (theme && theme.primary_button_settings && theme.primary_button_settings.font_color) || COLOR_TEXT_WHITE};
  }

  &.variant-secondary {
    color: ${({ theme }) =>
      (theme && theme.secondary_button_settings && theme.secondary_button_settings.font_color) || COLOR_TEXT_WHITE};
  }

  font-size: ${rem(10)};
  letter-spacing: 0.15em;
  ${cssLocalizedUppercase}
  font-weight: 500;
  text-align: center;

  background-color: transparent;
  border: 0;

  &:hover {
    text-decoration: none;
  }

  span {
    position: relative;
    z-index: 1;

    display: block;
    opacity: 0;

    transform: translateZ(0);
  }

  &.is-visible {
    span {
      animation: ${simpleFadeIn} forwards 900ms ${(props) => `${props["data-transition-delay"]}ms`}
        ${easing.easeOutQuart};
    }
  }
`;
