import React, { useRef, useEffect } from "react";

import ArticleExcerpt, { ArticleExcerptProps } from "../../../../components/article-excerpt";
import { ResponsiveWrapper, WrapperInner, List, Item } from "./style";

export interface ListController {
  getItemByIndex: (index: number) => HTMLElement | null;
}

export interface ListProps {
  articles: ArticleExcerptProps[];
  onInit: (controller: ListController) => void;
  className?: string;
  testId?: string;
}

const ListComponent: React.FC<ListProps> = ({ articles, onInit, className, testId }) => {
  const listRef = useRef<HTMLOListElement>(null);

  useEffect(() => {
    if (!onInit) return;

    onInit({
      getItemByIndex: (index) => {
        if (!listRef.current) return null;
        return listRef.current.children[index] as HTMLElement;
      },
    });
  }, []);

  return (
    <ResponsiveWrapper className={className}>
      <WrapperInner>
        <List ref={listRef}>
          {articles.map((article, index) => (
            <Item key={article.id}>
              <ArticleExcerpt {...article} hideHexOutline={true} testId={`${testId}:article-${index}`}/>
            </Item>
          ))}
        </List>
      </WrapperInner>
    </ResponsiveWrapper>
  );
};

export default ListComponent;
export { cssLayoutFn } from "./style";
