import styled, { css } from "styled-components";
import HexOutlineComponent from "../../../../components/hex-outline";
import { COLOR_PRIMARY_ORANGE } from "../../../../../lib/layouts/default/theme";
import { cssLocalizedUppercase } from "../../../../components/css";

export const Wrapper = styled.nav``;

export const WrapperInner = styled.div`
  position: relative;
  text-align: center;
`;

export const HexOutline = styled(HexOutlineComponent)`
  right: 2px;
  bottom: 2px;
`;

export const List = styled.ol`
  overflow: auto;
  max-width: 100%;
  position: relative;
  list-style: none;
  padding: 1px 0;
  margin: 0;
  display: inline-flex;
`;

export const Item = styled.li`
  font-size: 10px;
  height: 100%;
`;

export const Button = styled.button<{ active: boolean }>`
  display: block;
  height: 100%;
  padding: 0 10px;
  border: 0;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: 0.1em;

  font-weight: 600;
  ${cssLocalizedUppercase}
  color: #cacaca;

  border: 3px solid transparent;
  border-left: 0;
  border-right: 0;

  &:hover {
    color: black;
  }

  ${({ active }) =>
    active &&
    css`
      color: black;
      border-bottom-color: ${COLOR_PRIMARY_ORANGE};
    `}
`;

export const cssHexLayoutFn = () => css`
  ${List} {
    height: 50px;
    margin: 0 20px;
    max-width: calc(100% - ${20 * 2 + "px"});
  }
`;

export const cssLineLayoutFn = ({ sideMargin }: { sideMargin: number }) => css`
  ${WrapperInner} {
    border: 1px solid #c1c1c1;
    border-left: 0;
    border-right: 0;
    background-color: white;
  }

  ${HexOutline} {
    display: none;
  }

  ${List} {
    height: 30px;
  }

  ${Item} {
    &:first-child {
      padding-left: ${sideMargin + "px"};
    }
    &:last-child {
      padding-right: ${sideMargin + "px"};
    }
  }
`;
